<template>
  <div class="row pt-3">
    <div class="col">
      <!--
      <h4>Općenito o temperaturi i sirupima</h4>
      <p>
        TODO mivanci informacije o temperaturi i sirupima
      </p>
      -->
    </div>
  </div>
</template>

<script>
    export default {
        name: "Information"
    }
</script>

<style scoped>

</style>