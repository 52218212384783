<template>
  <footer class="my-5 pt-5 text-muted text-center text-small">
    <p>
      <small>
        Ikone izradila <a href="https://www.flaticon.com/authors/nikita-golubev"
                          title="Nikita Golubev">Nikita Golubev</a>, dostupno na
        <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>

        <br>

        Favicon generiran pomoću <a href="https://www.favicon-generator.org/">
        https://www.favicon-generator.org/</a>

        <br><br>

        <a rel="license" href="http://creativecommons.org/licenses/by/4.0/">
          <img alt="Creative Commons licenca" 
            style="border-width:0" 
            src="https://i.creativecommons.org/l/by/4.0/80x15.png" />
        </a>
        <br>
        Ovo djelo je dano na korištenje pod licencom 
        <a rel="license" 
          href="http://creativecommons.org/licenses/by/4.0/">
          Creative Commons Imenovanje 4.0 međunarodna
        </a>.
      </small>
    </p>
  </footer>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

</style>