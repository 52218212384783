<template>
  <div class="row pt-2">
    <div class="col">
      <p class="text-warning">
        Podaci dostupni na ovoj stranici su informativne prirode. Prije primjene sirupa savjetujte se sa doktorom ili
        ljekarnikom.
        Provjerite dali sirup kojeg koristite ima istu količinu lijeka kao što je navedeno na ovim stranicama.
        Izračun na ovoj stranici napravljen je za sirupe koji se često koriste na području Hrvatske.
      </p>
    </div>
  </div>
</template>

<script>
    export default {
        name: "Disclaimer"
    }
</script>

<style scoped>

</style>